@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --swiper-pagination-color: #fff;
    --swiper-pagination-bullet-inactive-color: #fff;
    --font-work: "Work Sans", sans-serif;
}

html,
body {
    font-family: var(--font-work);
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    height: 100%;
}

* {
    scroll-behavior: smooth
}

header {
    padding-top: 0.75rem;
    padding-left: 1.25rem; /* Equivalent to 5px */
    padding-right: 1.25rem; /* Equivalent to 5px */
}

footer {
    /*background-color: #333;*/
    color: white;
    padding: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.swiper-container {

    height: calc(100% - 120px); /* Adjust based on header and footer height */
    padding: 24px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Centering the slide content vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    color: #000;
    text-decoration: none;
    font-weight: 400;
}

.swiper-button-next,
.swiper-button-prev {
    @apply w-8 h-8 bg-gray-50 items-center justify-center text-black text-lg hover:bg-gray-100;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: ''; /* Remove default Swiper arrows */
}

.swiper-button-prev::before {
    content: '<'; /* Custom content for the previous button */
}

.swiper-button-next::before {
    content: '>'; /* Custom content for the next button */
}

/*.contact-info {*/
/*    height: calc(100% - 120px);*/
/*}*/

.contact-info p {
    margin-bottom: 1rem;
    letter-spacing: 0.03rem;
}

.contact-info a {
    font-weight: bold;
}

/*h1 a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
}*/



/*@media only screen and (max-width: 1024px) {
    body {
        background-color: lightblue;
    }

    h1 a {
        font-size: 56px;
    }
}*/
/*h1 {
    font-size: 2rem;
    !*margin: .67em 0;*!
}

h1 a {
    font-size: 3rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    line-height: 1;
}*/

/*footer {
    display: block;
}*/


/*.home-container {
    width: 100%;
    height: calc(100vh - 60px - 44px);
    display: flex;
    align-items: center;
    justify-content: center;
    !*padding: 1.25rem;*!
}*/

/*.swiper {
    width: 75vh;
    height: 600px;

    !*width: 55vh;*!
    !*min-width: 280px;*!

}
*/



/*.contact-info h2 {*/
/*    font-size: 2rem;*/
/*    padding: 0;*/
/*    margin: 1.5rem 0;*/
/*    font-weight: bold;*/
/*}*/

/*====================================*/

/*
.wrapper {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper {
    width: 50vmin;
    min-width: 280px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;
    width: 100%;
    height: 100%;
}
.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
}
.swiper-slide .card-contents {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-out;
}
.swiper-slide .card-contents img {
    border-radius: inherit;
}
.swiper-slide .card-description {
    position: absolute;
    bottom: 2em;
    z-index: 100;
    color: white;
    margin-left: 1.5em;
}
.swiper-slide .card-description h1, .swiper-slide .card-description p {
    margin: 0;
    text-align: left;
    text-shadow: 1vmin 0 4vmin #000;
}
.swiper-slide .card-description h1 {
    font-size: clamp(2em, 4vw, 4em);
}
.swiper-slide .card-description p {
    font-size: 1em;
}
.swiper-button-next, .swiper-button-prev {
    color: #fff;
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px;
}
*/
